<template>
  <div class="video-page">
    <router-link class="close-icon" to="/">
      <img
          src="@/assets/images/close-icon.svg"
          alt="close-icon"
          width="47"
          height="47">
    </router-link>
    <div class="video-box">
      <video src="@/assets/video.mp4" controls></video>
    </div>
    <p class="video__text">
      At str-ing.org, we think web 3.0 is the native environment for the digital arts, research & collective thinking.
    </p>
  </div>
</template>

<script>
export default {
  name: "Video",
}
</script>

<style lang="scss" scoped>
.video-page {
  height: 100vh;
  padding: 10px 8px;
  display: flex;
  flex-direction: column;
}
.video-box {
  position: relative;
  background: #000000;
  border-radius: 5px;
  height: 100%;
  width: 100%;
  overflow: hidden;
  video {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}
.video__text {
  font-family: 'Akkurat';
  margin: 9px 0 0;
  font-weight: 400;
  font-size: 12px;
  line-height: 15px;
  text-align: center;
}
.close-icon {
  display: none;
}

@media screen and (min-width: 1200px) {
  .video-page {
    padding: 100px 160px;
    justify-content: center;
    align-items: center;
  }
  .video-box {
    border-radius: 14px;
    max-width: 1590px;
    max-height: 883px;
  }
  .video__text {
    display: none;
  }
  .close-icon {
    display: block;
    position: absolute;
    top: 33px;
    right: 36px;
  }
}
</style>
